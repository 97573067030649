<template>

<div style="height: 500px; width: 100%">
    <p>
      <button @click="showMap = !showMap"> Show/Hide map</button>
      <button @click="followMyPosion = !followMyPosion">Follow/Unfollow position</button>
      <button @click="showSamples = !showSamples; getSamples() ">show positions</button>
    </p>
    <l-map
      v-if="showMap"
      :zoom="zoom"
      :center="center"
      :options="mapOptions"
      style="height: 100%"
      @update:zoom="zoomUpdate"
      @update:center="center"
    >
      <l-tile-layer
        :url="url"
        :attribution="attribution"
      />
      <l-marker :lat-lng="withPopup">
        <l-popup>
          <div @click="innerClick">
            I'm here: {{center.lat}} {{center.lng}} 
            <p v-show="showParagraph">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
              sed pretium nisl, ut sagittis sapien. Sed vel sollicitudin nisi.
              Donec finibus semper metus id malesuada.
            </p>
          </div>
        </l-popup>
      </l-marker>
      <l-polyline :lat-lngs="latlngs" :color="red"></l-polyline>
    </l-map>
    <ul v-if="showSamples">
      <li :key="s.ts" v-for="(s, i) in samples">{{i}}) {{s.lat}} {{s.lng}}</li>
    </ul>

  </div>

</template>

<script>
import { latLng } from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup, LPolyline } from "@vue-leaflet/vue-leaflet";
import { usePositionStore } from '@/stores/position'

export default {
  name: "ShowMap",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LPolyline,
  },

  data() {
    return {
      zoom: 15,
      //center: latLng(47.41322, -1.219482),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors, ',
//      withPopup: latLng(47.41322, -1.219482),
//      withTooltip: latLng(47.41422, -1.250482),
      currentZoom: 15,
      //currentCenter: latLng(47.41322, -1.219482),
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5
      },
      showMap: true,
      showSamples: false,
      samples: [],
      followMyPosion: true,
    };
  },

  methods: {
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    innerClick() {
      //alert("Click!");
    },

    getSamples () {
      const positionStore = usePositionStore();
      const positions = positionStore.positions;
      if (!positions) return null;
      this.samples = positions;
    },

  },

  computed: {
    withPopup () {
      const positionStore = usePositionStore();
      const p = positionStore.currentPosition;
      //alert(p.lat + " " + p.lng+ " at " + p.ts );
      if (!p) return null;
      return latLng(p.lat, p.lng);
    },

    center () {
      if (!this.followMyPosion) return this.center;

      const positionStore = usePositionStore();
      const p = positionStore.currentPosition;
      //alert(p.lat + " " + p.lng+ " at " + p.ts );
      if (!p.lat){
        return latLng(39.202590, 9.207598); // golfo di CA
      } 
      return latLng(p.lat, p.lng);
    },

    latlngs () {
      const positionStore = usePositionStore();
      const positions = positionStore.positions;
      const out = [];
      if (!positions) return out;
      //let i = 0.1;
      positions.forEach(p => {
        //i += 0.1;
        out.push([p.lat, p.lng])
      });
      return out;

        //return [[47.334852, -1.509485], [47.342596, -1.328731], [47.241487, -1.190568], [47.234787, -1.358337]]
    }

  },

  mounted() {
  }
};
</script>

<style>

</style>
