import { defineStore } from 'pinia'

export const usePositionStore = defineStore('position', {
  state: () => {
    return { 
        currentPosition: {},
        positions: [] 
    }
  },

  getters: {
    getPosition(state) {
        return state.currentPosition;
    },
    getPositions(state) {
        return state.positions;
    },
  },

  actions: {
    setPosition(p) {
      this.currentPosition = p;
      this.positions.push(p);
      //console.log(this.positions.length)
    },
  },
})