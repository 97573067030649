<template>
    <input v-model="boatid" placeholder="numero velico" />   
    <input v-model="boatname" placeholder="nome barca" />   
    <p>
      {{lat}} {{lng}} at {{timestamp}} 
      <button @click="load()">reload position</button>
    </p>
    <p>{{message}} </p>
    
    <p> Sampling rate (in ms) 
      <input v-model="rate" placeholder="3000" />   
      <button v-if="timer==null" @click="startLoading()">start</button> 
      <button v-if="timer!=null" @click="stopLoading()">stop</button>
    </p>
</template>

<script>
import { usePositionStore } from '@/stores/position'

export default {
  name: 'ShowLocation',
    
  props: {
    //error: String
  },

  data: () => {
    return {
      boatid: '',
      boatname: '',
      rate: '3000',
      timestamp: null,
      ts: null,
      //counter: 0, 
      lat: 0,
      lng: 0,
      message: '',
      timer: null
    }
  },

  methods :{
      load() {
          let pos = (position) => {
              //alert("Lat: " + position.coords.latitude + "\nLon: " + position.coords.longitude);
              this.lat = position.coords.latitude;
              this.lng = position.coords.longitude;
              this.getNow();
              this.message = '';
              const positionStore = usePositionStore()
              positionStore.setPosition({lat: this.lat, lng: this.lng, ts: this.ts})
          }

          let err = (error) => {
              this.message = error.message;
              //alert(error.message);
          }

          navigator.geolocation.getCurrentPosition( pos, err, {
              enableHighAccuracy: true, 
              timeout : 5000
          });
      },

      startLoading() {
          if (!this.timer) {
              this.timer = setInterval( () => this.load(), parseInt(this.rate));
          }
      },

      stopLoading() {
          clearInterval(this.timer);
          this.timer = null;
      },

      getNow() {
        const today = new Date();
        const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        const dateTime = date +' '+ time;
        this.timestamp = dateTime;
        this.ts = today.valueOf();
      }



  },

  created() {
    this.load();
  }

}



</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
